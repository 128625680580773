<template>
    <div class="products">
        <div v-if="this.isFetching" class="product-loading">
            <div class="lds-ring">
                <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
            </div>
        </div>

        <div v-else class="product">
            <Breadcrumb v-bind:item="this.product.shortName"/>
            
            <div class="product-wrap">
                
                <div class="product-grid">
                    <img :src="this.product.bottleImage.fluid.src"  alt="product">

                    <div class="product-info-main">
                        <SectionHeader v-bind:title="this.product.shortName"/>
                        <a v-if="product.producer.exhibitorId" @click="goToExhibitor(product.producer.slug, product.producer.exhibitorId)" class="btn-contact">Contact IMPORTER</a>
                        <div class="product-info">
                            
                            <div class="item">
                                <p class="info-name">WINE NAME</p>
                                <p class="info-value">{{ this.product.shortName }}</p>
                            </div>

                            <div class="item">      
                                <p class="info-name">VINTAGE</p>
                                <p class="info-value">{{this.product.vintage}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">PRODUCER</p>
                                <p class="info-value">{{this.product.producer.name}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">WINE COLOUR</p>
                                <p class="info-value">{{this.product.wineColor}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">GRAPE VARIETIES</p>
                                <p class="info-value">{{this.product.grapeVarieties[0].varietyName}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">ALCOHOL ON LABEL</p>
                                <p class="info-value">{{this.product.alcohol}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">WINE TYPE</p>
                                <p class="info-value">{{this.product.wineType}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">WINE STYLE</p>
                                <p class="info-value">{{this.product.designation}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">COUNTRY</p>
                                <p class="info-value">{{this.product.country}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">REGION</p>
                                <p class="info-value">{{this.product.region}}</p>
                            </div>

                            <div class="item">
                                <p class="info-name">SUB REGION</p>
                                <p class="info-value">{{this.product.subregion}}</p>
                            </div>
                            <div class="item">
                                <p class="info-name">TASTING NOTES FOR TRADE</p>
                                <p class="info-value info-tasting" v-if="this.product.tastingNotes">{{this.product.tastingNotes.note}}</p>
                                <p class="info-value info-tasting" v-else></p>
                            </div>

                            <div class="item">
                                <p class="info-name">AWARDS</p>
                                <p class="info-value" v-if="this.product.awards && this.product.awards.length > 0 ">{{this.product.awards[0].award}}   {{this.product.awards[0].medal}}</p>
                                <p class="info-value" v-else></p>
                            </div>

                            <div class="item">
                                <p class="info-name">OTHER AWARDS</p>
                                <p class="info-value info-other-awards info-value--spaced">{{this.product.otherAwards}}</p>
                            </div>

                            <div class="product-desc">
                                <h3>ABOUT</h3>
                                <p>{{this.product.description}}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- <div class="related-products">
                    <h2>RELATED WINES</h2>

                    <div class="related-grid">
                        <div class="product" v-for="n in 3" v-bind:key="n">
                            <div class="col">
                                <img src="https://via.placeholder.com/210x280" alt="product">
                            </div>

                            <div class="col">
                                <h2 class="product-title">Wine title {{n}}</h2>
                                <p class="product-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis imperdiet et orci quis vulputate....</p>
                                <router-link to="Product">READ MORE</router-link>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue'

import gql from 'graphql-tag'


export default {
    name: 'Product',

    components: { 
        SectionHeader,
        Breadcrumb
    },

    data() {
        return {
            title: 'Product Title',
            isFetching: true,
            productId: this.$route.params.productId,
            exhibitorId: this.$route.params.exhibitorId,
            product: [],
            related: []
        }
    },

    mounted() {
        this.productId = this.$route.params.productId;
        this.exhibitorId = this.$route.params.exhibitorId;
    },

    methods: {
        getYear(string) {
            return string.replace( /^\D+/g, '');
        },
        goToExhibitor(slug, exhibitorId) {
            this.$router.push({name:'exhibitor', params:{slug:slug, exhibitorId:exhibitorId}})
        },
    },

    apollo: {

        product: {
            query: gql`query ($productId: ID!) {
                event(eventId: "5f842ed57507225ccb782eb4") {
                    product (productId:$productId) {
                        bottleImage {
                            fluid(maxWidth: 420, maxHeight:560, crop: PAD){
                                src
                            }
                        }
                        shortName
                        name
                        bottlingDate
                        producer {
                            name
                            email
                            slug
                            exhibitorId
                        }

                        ... on Wine {
                            alcohol
                            country
                            region
                            subregion
                            otherAwards
                            description
                            wineColor
                            wineType
                            designation
                            vintage
                            
                            tastingNotes {
                                note
                            }

                            grapeVarieties {
                                varietyName
                            }

                            awards {
                                award
                                medal
                            }
                        }
                    }
                }
            }`,

            fetchPolicy: 'network-only',

            variables() {
                return {
                    productId: this.productId,
                    exhibitorId: this.exhibitorId
                }
            },

            result ({ data, loading }) {
                if (!loading) {
                    // assign all product to data.product
                    this.product = data.event.product
                    this.isFetching = false;
                }
            },
        },
    }
}
</script>